import { observable, action, override, makeObservable, computed } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { getSearchParams } from 'utils/queryString.utils'
import { isEmpty } from 'utils/validatorsShared.utils'
import { ROOT_URL } from 'constants/general.constants'
import { DELETION_STATES } from 'constants/sellerDeletion.constants'
import { patchLink } from 'utils/link.utils'

import { SellerApi, createSellerApi, Seller, SellerCheckout } from 'shop/api/seller.api'

export class SellerStore extends SharedStore<any> {
  storeName = 'SellerStore'
  declare childApi: SellerApi

  constructor(root: ShopRootStore) {
    super()
    this.childApi = createSellerApi(root?.apiClientV2)
    makeObservable(this)
  }

  @override
  async fetchItem(data) {
    const resp = await this.childApi.fetchItem(data)

    this.item = resp.data as SellerCheckout
    return resp
  }

  async fetchReducedItem(data) {
    const resp = await this.childApi.fetchReducedItem(data)

    this.item = resp.data as Seller
    return resp
  }

  @observable protectByPass = false
  @observable lockedByToken = false
  @observable productPassword = ''

  @action setProtectByPass = (pass?: string) => {
    this.protectByPass = !!pass
    this.productPassword = pass
  }

  @action setLockingByToken = async (protectionUrl: string, params?: { protection_token: string }) => {
    if (protectionUrl) {
      this.lockedByToken = true
      await this.checkProtectionToken(protectionUrl, params)
    } else {
      this.lockedByToken = false
    }
  }

  @action checkProtectionToken = async (protectionUrl, params?: { protection_token: string }): Promise<void> => {
    const { protection_token } = params || getSearchParams()

    // TODO: SSR - do we need 'fetch' here?
    // protectionUrl - in Node env should be full url (fetch limitation)
    const alreadyFullUrl = /^http?/.test(protectionUrl)
    const url = alreadyFullUrl ? protectionUrl : patchLink(`${ROOT_URL}/${protectionUrl.replace(/^\//, '')}`)
    // relative protectionUrl (typed by user in cabinet) doesn't work localy
    const resp = await fetch(url.replace('%{protection_token}', protection_token), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    // @ts-ignore
    this.lockedByToken = !resp.success
  }

  isAppActive = (key: string) => this.item.optionKeys && this.item.optionKeys.indexOf(key) >= 0

  @computed get isInDeactivationPhase() {
    const { deletionState } = this.item

    // @ts-ignore
    return deletionState != null && deletionState !== DELETION_STATES.empty && deletionState !== 0
  }

  @computed get sellerId() {
    return this.item.id
  }

  @override hydrate(key, data) {
    if (key === 'item') {
      // TODO: rm after EVERY response will be covered by types
      // @ts-ignore
      this.setItem({ data: data, success: !isEmpty(data) })
    } else if (key === 'lockedByToken') {
      this.lockedByToken = data
    } else if (key === 'protectByPass') {
      this.protectByPass = data
    } else if (key === 'productPassword') {
      this.productPassword = data
    }
  }
}
