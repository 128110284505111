import { getElopageConfig } from 'utils/elopageConfig.utils'
import { patchLink } from 'utils/link.utils'

const apiPath = getElopageConfig('apiPath')

const sendPostMessage = (iframe, key, value) => iframe.contentWindow.postMessage({ key, value }, '*')

const sendPostMessageOnLoad = (iframe, key, value, callback) => {
  iframe.addEventListener('load', () => {
    sendPostMessage(iframe, key, value)
    iframe.loaded = true
    callback()
  }, false)
}

export function setSessionCookie(key, value) {
  const iframeId = 'cookies-session-iframe'
  let iframe = document.getElementById(iframeId)
  let sended = false
  if (!iframe) {
    iframe = document.createElement('iframe')
    sendPostMessageOnLoad(iframe, key, value, () => sended = true)
    iframe.loaded = false
    iframe.style.display = 'none'
    iframe.id = iframeId
    iframe.src = patchLink(`${apiPath}/hsm`)

    document.body.appendChild(iframe, key, value)
  } else if (iframe.loaded) {
    sendPostMessage(iframe, key, value)
    sended = true
  } else {
    sendPostMessageOnLoad(iframe, key, value, () => sended = true)
  }

  async function returnWhenSended() {
    const repeatRequestDelay = 500

    await new Promise((resolve) => setTimeout(resolve, repeatRequestDelay))
    if (!sended) {
      return returnWhenSended()
    }
  }

  return returnWhenSended()
}

export default setSessionCookie
