import { getCookie, getCookies as getCookiesAll, setCookie, deleteCookie } from 'cookies-next';
import { ELOPAGE_PREFIX, NEW_ELOPAGE_PREFIX } from 'constants/domains.constants'
import { COOKIES_CONSENTS } from '@elo-kit/constants/cookiesConsents.constants'
// eslint-disable-next-line import/no-cycle
import { validators } from 'utils/validators.utils'
import { getElopageConfig } from 'utils/elopageConfig.utils'
import { isWindowEnv } from 'utils/env.utils'
import { setSessionCookie } from './crossDomainCookies'

const hostname = isWindowEnv() ? window.location.hostname : getElopageConfig('hostname')
const newDomain = getElopageConfig('newWebHost') || ''
const isNewHostname = hostname === newDomain
const domain = isNewHostname ? getElopageConfig('newCookiesDomain') : getElopageConfig('cookiesDomain') || ''
const env = getElopageConfig('env')

const cookies = {
  get: getCookie,
  set: setCookie,
  remove: deleteCookie,
  getAll: getCookiesAll,
}

// TODO: SSR - how to handle customDomain on SSR
const customDomain = isWindowEnv() && hostname ? ((!hostname.includes(domain.replace(/^\.?/, ''))) || (hostname.includes(NEW_ELOPAGE_PREFIX)) || (hostname.includes(ELOPAGE_PREFIX))) : ''
const defaultOptions = !customDomain ? { path: '/', domain } : { path: '/', domain: hostname }
export const cookiesKey = (key) => `${env?.[0]}_${key}`
const inIframe = (() => {
  try {
      return window.self !== window.top
  } catch (e) {
      return true
  }
})()

export const getCookies = (key, useEnvPrefix = true) => cookies.get(useEnvPrefix ? cookiesKey(key) : key)
export const getCookiesOnServer = (key, context, useEnvPrefix = true) => cookies.get(useEnvPrefix ? cookiesKey(key) : key, { req: context.req, res: context.res })
export const setCookies = async (key, value, options = {}) => {
  /*
    Same Site policy must be set for iFrame with secure flag
    https://bugs.chromium.org/p/chromium/issues/detail?id=1062162
  */
  const iframeOptions = (inIframe && (key.startsWith(COOKIES_CONSENTS.shop))) ? { sameSite: 'none', secure: true } : {}

  cookies.set(cookiesKey(key), value, { ...defaultOptions, ...options, ...iframeOptions })

  if (customDomain) {
    await setSessionCookie(cookiesKey(key), value)
  }
}
export const setCookiesOnServer = (key, value, context, options = {}) => {
  cookies.set(cookiesKey(key), value, { req: context.req, res: context.res, ...options })
}

export const removeCookies = (key) => {
  const removeFromAllSubdomains = (domain) => {
    const isTopLvlDomain = domain.split('.').length <= 2

    cookies.remove(cookiesKey(key), { ...defaultOptions, domain })
    // Temp solution for deleting cookies with old keys, after some time can be removed
    cookies.remove(key, { ...defaultOptions, domain })

    if (isTopLvlDomain || validators.isIP(domain)) return
    removeFromAllSubdomains(domain.replace(/\.[a-zA-Z]+/, ''))
  }

  removeFromAllSubdomains(defaultOptions.domain || hostname)
}

export const getAllCookies = () => {
  return cookies.getAll()
}

export const userSessionCookieKey = 'user-session-id'