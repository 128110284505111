import React from 'react'
import { observer } from 'mobx-react'

import { getCookies, setCookies, removeCookies } from 'libs/common/cookies'
import { patchLink } from 'utils/link.utils'

import './admin-mode.scss'

interface Props {
  userEmail: string
}

export const AdminMode: React.FC<Props> = observer(({ userEmail }) => {
  const handleBackToAdmin = (adminAccessToken) => {
    Promise.all([setCookies('access_token', adminAccessToken), removeCookies('admin_access_token')]).then(() => {
      const redirectUrl = (getCookies('admin_redirect_url') || '/admin') as string
      removeCookies('admin_redirect_url')

      window.location.href = patchLink(redirectUrl)
    })
  }

  const adminAccessToken = getCookies('admin_access_token') as string

  return adminAccessToken && adminAccessToken.length ? (
    <div className='admin-mode'>
      <span>
        {'You are currently viewing the site under a '}
        <b>{`${userEmail}'s`}</b>
        {' account'}
      </span>
      <div className='back-to-admin' onClick={() => handleBackToAdmin(adminAccessToken)}>
        Back to the manager mode
      </div>
    </div>
  ) : null
})
