import React from 'react'
import withStyles from 'react-jss'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { EloSnackbar } from '@elo-kit/components/elo-ui/snackbar'
import { useI18n } from '@elo-kit/components/i18n/i18n'
import { internalURL } from '@elo-kit/utils/contentBlock.utils'
import { patchLink } from 'utils/link.utils'

import cookiesConsentSimpleStyles from './CookiesConsentSimple.styles'

/**
 * Simple Cookies Consent view
 */
export const CookiesConsentSimple = (props) => {
  const {
    privacyLink,
    classes,
    selectedCategories,
    handleSelectAll,
    toggleModal,
    preview,
    selected,
    reportCookieConsent,
    legalLinks = [],
    username,
    handleDeny,
    baseURL,
    powerSellerParam,
    themePages,
  } = props

  const I18n = useI18n()

  const simpleConsentClasses = classNames(classes.simpleConsent, {
    [classes.preview]: preview,
  })

  const categoriesListClasses = classNames(classes.simpleCategoriesList, {
    [classes.categoriesListPreview]: preview,
  })

  return (
    <EloSnackbar
      action={() => {
        handleSelectAll()
        reportCookieConsent('accept_all', { source: 'accept_all_from_banner' })
      }}
      btnText={I18n.t('react.shared.cookies_consent.rich.accept_all')}
      settingsBtnText={I18n.t('react.shared.cookies_consent.rich.customize')}
      className={simpleConsentClasses}
      additionalAction={() => {
        handleDeny()
        reportCookieConsent('accept_custom', {
          source: 'accept_custom_from_banner',
          selected: Object.keys(selected).filter((val) => !!selected[val]),
          selectedCategories: Object.keys(selectedCategories).filter((val) => !!selectedCategories[val]),
        })
      }}
      additionalBtnText={I18n.t('react.shared.cookies_consent.rich.deny')}
      handleSettings={() => {
        toggleModal()
        reportCookieConsent('open_change_settings', { source: 'open_change_settings_from_banner' })
      }}
    >
      <span className={classes.simpleDescription}>{`${I18n.t('react.shared.cookies_consent.simple.message')} `}</span>
      <a target='_blank' href={patchLink(privacyLink)}>
        {I18n.t('react.shared.cookies_consent.simple.more_info')}
      </a>
      <div className={categoriesListClasses}>
        {legalLinks.map((item, idx) =>
          item.submenu.map((subitem) => (
            <a
              key={idx}
              target='_blank'
              href={patchLink(`${internalURL(baseURL, subitem, themePages, username)}${powerSellerParam}`)}
            >
              {subitem.name}
            </a>
          ))
        )}
      </div>
    </EloSnackbar>
  )
}

CookiesConsentSimple.propTypes = {
  /** Privacy Link */
  privacyLink: PropTypes.string,
  /** Categories list */
  categories: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /** Selected Categories list */
  selectedCategories: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /** Cookie Consents by Categories list */
  consentsByCategories: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /** Accept cookies handler */
  acceptCookies: PropTypes.func,
  /** Toggle modal handler */
  toggleModal: PropTypes.func,
  /** Select all handler */
  handleSelectAll: PropTypes.func,
  /** Category check handler */
  handleCategoryCheck: PropTypes.func,
  /** React JSS classes */
  classes: PropTypes.object,
  /** Preview flag */
  preview: PropTypes.bool,
}

CookiesConsentSimple.defaultProps = {
  categories: [],
  selectedCategories: {},
  consentsByCategories: {},
}

/**
 * Simple cookies consent view with React JSS styles
 */
export const CookiesConsentSimpleWithStyles = withStyles(cookiesConsentSimpleStyles)((props) => (
  <CookiesConsentSimple {...props} />
))
