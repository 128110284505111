import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'

import { EloModal } from '@elo-kit/components/elo-ui/elo-modal/EloModal'
import { CollapsibleSubitemsList } from '@elo-kit/components/elo-ui/collapsible-subitems-list/CollapsibleSubitemsList'
import { EloButton } from '@elo-kit/components/elo-button'
import { LoadingSpinner } from '@elo-kit/components/loading-spinner'
import { useI18n } from '@elo-kit/components/i18n/i18n'
import { internalURL } from '@elo-kit/utils/contentBlock.utils'

import { COOKIES_CONSENTS_TYPE } from '@elo-kit/constants/cookiesConsents.constants'
import { SIZES } from '@elo-kit/constants/general.constants'
import { patchLink } from 'utils/link.utils'

import cookiesConsentRichStyles from './CookiesConsentRich.styles'

/**
 * Rich Cookies Consent view
 */
export const CookiesConsentRich = (props) => {
  const {
    privacyLink,
    classes,
    dataInited,
    showModal,
    selected,
    consentsByCategories,
    categories,
    selectedCategories,
    handleSelectAll,
    acceptCookies,
    toggleModal,
    handleCheck,
    handleCategoryCheck,
    accepted,
    form,
    reportCookieConsent,
    handleDeny,
    legalLinks = [],
    username,
    baseURL,
    powerSellerParam,
    themePages,
  } = props

  const I18n = useI18n()

  return (
    <EloModal
      isOpen={showModal}
      className={classes.richConsent}
      title={I18n.t('react.shared.cookies_consent.rich.title')}
      toggle={toggleModal}
      size={SIZES.large}
      cancel={false}
      close={accepted || form === COOKIES_CONSENTS_TYPE.simple}
    >
      <EloModal.Message>
        <div
          dangerouslySetInnerHTML={{
            __html: I18n.t('react.shared.cookies_consent.rich.description', { link: privacyLink }),
          }}
        />
        {form === COOKIES_CONSENTS_TYPE.rich && (
          <div>
            {legalLinks.map((item, idx) =>
              item.submenu.map((subitem) => (
                <a
                  key={idx}
                  target='_blank'
                  href={patchLink(`${internalURL(baseURL, subitem, themePages, username)}${powerSellerParam}`)}
                >
                  {subitem.name}
                </a>
              ))
            )}
          </div>
        )}
      </EloModal.Message>

      {dataInited ? (
        <CollapsibleSubitemsList
          list={categories}
          subitemsList={consentsByCategories}
          itemAction={(item) => handleCategoryCheck(item.id)}
          subitemAction={(item) => handleCheck(item.id)}
          checkedSubitems={selected}
          checkedItems={selectedCategories}
          tooltipClasses={classes.tooltip}
        />
      ) : (
        <LoadingSpinner />
      )}

      <div className='elo-modal__bottom-buttons elo-modal__bottom-buttons--flex'>
        <EloButton
          onClick={() => {
            acceptCookies()
            reportCookieConsent('accept_custom', {
              source: 'accept_custom_from_modal',
              selected: Object.keys(selected).filter((val) => !!selected[val]),
              selectedCategories: Object.keys(selectedCategories).filter((val) => !!selectedCategories[val]),
            })
          }}
        >
          {I18n.t('react.shared.cookies_consent.rich.accept')}
        </EloButton>
        <EloButton
          onClick={() => {
            handleDeny()
            reportCookieConsent('accept_custom', {
              source: 'accept_custom_from_modal',
              selected: Object.keys(selected).filter((val) => !!selected[val]),
              selectedCategories: Object.keys(selectedCategories).filter((val) => !!selectedCategories[val]),
            })
          }}
        >
          {I18n.t('react.shared.cookies_consent.rich.deny')}
        </EloButton>
        <EloButton
          onClick={() => {
            handleSelectAll()
            reportCookieConsent('accept_all', { source: 'accept_all_from_modal' })
          }}
        >
          {I18n.t('react.shared.cookies_consent.rich.accept_all')}
        </EloButton>
      </div>
    </EloModal>
  )
}

CookiesConsentRich.propTypes = {
  /** Privacy Link */
  privacyLink: PropTypes.string,
  /** Categories list */
  categories: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /** Selected Categories list */
  selectedCategories: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /** Cookie Consents by Categories list */
  consentsByCategories: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /** Accept cookies handler */
  acceptCookies: PropTypes.func,
  /** Toggle modal handler */
  toggleModal: PropTypes.func,
  /** Select all handler */
  handleSelectAll: PropTypes.func,
  /** Category check handler */
  handleCategoryCheck: PropTypes.func,
  /** Item check handler */
  handleCheck: PropTypes.func,
  /** React JSS classes */
  classes: PropTypes.object,
  /** Show Modal flag */
  showModal: PropTypes.bool,
  /** Data Inited flag */
  dataInited: PropTypes.bool,
  /** Selected flag */
  selected: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  /** Accepted flag */
  accepted: PropTypes.bool,
  /** Cookie consent form */
  form: PropTypes.string,
}

CookiesConsentRich.defaultProps = {
  categories: [],
  selectedCategories: {},
  consentsByCategories: {},
}

/**
 * Rich cookies consent view with React JSS styles
 */
export const CookiesConsentRichWithStyles = withStyles(cookiesConsentRichStyles)((props) => (
  <CookiesConsentRich {...props} />
))
